import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { sentry } } = useRuntimeConfig()

  if (!sentry.dsn)
    return

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    tracesSampleRate: 0.2,

    tracePropagationTargets: ['https://web.staging.saudetrevo.com.br', 'https://saudetrevo.com.br'],

    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  })
})
