<script setup lang="ts">
defineProps({
  modelValue: {
    type: String,
    required: true,
  },
})

defineEmits(['update:modelValue'])

const mask = '+55 (##) 9####-####'
const size = mask.length
</script>

<template>
  <InputText
    v-bind="$props"
    :mask="mask"
    :min="size"
    :max="size"
    inputmode="tel"
    error-message="Preenchimento obrigatório"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>
