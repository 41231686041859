<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useField } from 'vee-validate'
import { z } from 'zod'
import type { ZodString } from 'zod'

interface IInputTextProps {
  name: string
  type?: string
  placeholder?: string
  errorMessage?: string
  min: number
  max: number
  inputmode?:
    | 'text'
    | 'decimal'
    | 'numeric'
    | 'tel'
    | 'search'
    | 'email'
    | 'url'
  validation?: ZodString
  mask?: string
  disabled?: boolean
  required?: boolean
  modelValue: string
  label?: string
  align?: 'left' | 'center' | 'right'
}

const props = withDefaults(defineProps<IInputTextProps>(), {
  type: 'text',
  placeholder: '',
  errorMessage: '',
  inputmode: 'text',
  mask: '',
  label: '',
  disabled: false,
  required: false,
  validation: ({ min, max, required, errorMessage }: IInputTextProps) => {
    const validations = z
      .string()
      .min(min || 0, errorMessage || `Insira pelo menos ${min} caracteres.`)
      .max(max || 100, errorMessage || `Insira no máximo ${max} caracteres.`)

    if (!required)
      return z.optional(validations)

    return validations
  },
  align: 'left',
})

const { value, errorMessage } = useField(
  () => props.name,
  toTypedSchema(props.validation),
  { syncVModel: true },
)
</script>

<template>
  <div class="flex flex-col w-full relative">
    <div
      class="h-14 p-0 rounded-lg peer focus:outline-none bg-transparent relative"
    >
      <label v-if="props.label" :for="props.name" class="sr-only">
        {{ props.label }}:
      </label>
      <input
        :id="props.name"
        v-model="value"
        v-maska
        :class="{
          [`text-${props.align}`]: true,
          'text-neutral-30 bg-neutral-80': disabled,
          'border-neutral-60 border-t-transparent hover:border-t-transparent  duration-300 placeholder placeholder-shown:border-t-neutral-60  focus:border-primary-50':
            !errorMessage,
          'border-error-50 border-t-transparent text-error-50': errorMessage,
        }"
        class="peer h-full w-full rounded-lg border bg-transparent duration-200 px-3 py-2.5 font-sans text-sm font-bold text-neutral-20 outline outline-0 transition-all placeholder-shown:border focus:border-2 focus:border-t-transparent focus:outline-0"
        placeholder=" "
        :type="props.type"
        :data-maska="props.mask"
        :disabled="props.disabled"
      >
      <span
        class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full text-neutral-60 w-full select-none text-sm font-normal leading-tight transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-neutral-60 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-neutral-60 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-hover:text-[11px] peer-hover:leading-tight peer-hover:mt-0 peer-hover:before:border-t-2 peer-hover:before:border-l-2 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:mt-0 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-hover:border-neutral-60 peer-hover:after:border-neutral-60 peer-hover:before:border-neutral-60 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-hover:after:border-t peer-hover:after:border-r duration-100 before:duration-100 after:duration-100"
        :class="{
          'mt-0': value,
          'mt-[6px]': !value,
          'peer-focus:after:border-error-50 peer-hover:after:border-error-50 peer-hover:before:border-error-50 after:border-error-50 peer-focus:text-error-50 peer-focus:before:border-error-50 before:border-error-50 peer-placeholder-shown:text-error-50 peer-hover:text-error-50 ':
            errorMessage,
          'peer-focus:after:border-primary-50 peer-focus:text-primary-50 peer-focus:before:border-primary-50  peer-placeholder-shown:text-neutral-30':
            !errorMessage,
        }"
      >
        {{ props.placeholder }}
      </span>
    </div>

    <div v-if="errorMessage" class="flex items-center">
      <Icon name="ic:info" class="w-4 h-4 text-error-50 mr-2" />
      <small class="text-red-500 mt-[3px]">{{ errorMessage }}</small>
    </div>
  </div>
</template>
