import { isJWT } from '@/validators/helpers'
import { jwtDecode } from 'jwt-decode'
import type { RouteLocationNormalized } from '#vue-router'
import type { IAccessTokenDecoded, IRefreshTokenDecoded } from '@/modules/core/types'

const PROTECTED_ROUTES = [
  'checkout-endereco-de-cobranca',
  'checkout-forma-de-pagamento',
  'checkout-pagamento-aprovado',
  'checkout-pedido-medico',
  'checkout-pedido-realizado',
  'checkout-processando-pagamento',
  'checkout-qr-code',
  'checkout-revisão-de-dados',
  'perfil',
  'perfil-historico-de-exames',
  'perfil-meus-cupons',
  'perfil-dados-cadastrais',
]

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (import.meta.client) {
    const { token } = to.query

    const { accessToken, showSignInModal, refreshToken, logout } = useAuth()

    if (
      token
      && typeof token === 'string'
      && isJWT(token)
    ) {
      accessToken.value = token

      return
    }

    if (localStorage.getItem('AccessToken')) {
      const { exp: accessTokenExp }: IAccessTokenDecoded = jwtDecode(localStorage.getItem('AccessToken') as string)

      accessToken.value = localStorage.getItem('AccessToken') as string

      if (!localStorage.getItem('RefreshToken'))
        return

      refreshToken.value = localStorage.getItem('RefreshToken') as string
      const { exp: refreshTokenExp }: IRefreshTokenDecoded = jwtDecode(localStorage.getItem('RefreshToken') as string)

      const now = new Date()

      if (now > new Date(accessTokenExp * 1000)) {
        if (now > new Date(refreshTokenExp * 1000))
          return logout()

        useAuthRefreshToken()
      }

      return
    }

    const toIsProtectedRoute = PROTECTED_ROUTES.includes(to.name as string)

    // Usuário não autenticado advindo via URL.
    if (!from.name) {
      if (toIsProtectedRoute) {
        onNuxtReady(() => {
          showSignInModal.value = true

          return navigateTo('/')
        })
      }

      return
    }

    // Usuário não autenticado indo para uma rota que não necessita de autenticação
    if (!toIsProtectedRoute)
      return

    // Usuário não autenticado advindo de navegação interna.
    showSignInModal.value = true

    return abortNavigation()
  }
})
