<script lang="ts" setup>
type TButtonType = 'button' | 'submit' | 'reset'

type IButtonVariant =
  | 'default'
  | 'success'
  | 'outlined'
  | 'outlinedWhite'
  | 'text'
  | 'yellow'
  | 'error'
  | 'white'
  | 'disabled'

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String as PropType<IButtonVariant>,
    default: 'default',
  },
  full: {
    type: Boolean,
    default: false,
  },
  buttonType: {
    type: String as PropType<TButtonType>,
    default: 'button',
  },
})

const variantClass = computed(() =>
  props.disabled ? 'disabled' : props.variant,
)
</script>

<template>
  <button
    :class="`button ${variantClass} ${full ? 'full' : ''} ${
      loading ? 'loading' : ''
    }`"
    :disabled="disabled || loading"
    :type="buttonType"
  >
    <template v-if="props.loading">
      <span>
        <Icon
          name="mingcute:loading-fill"
          class="w-5 h-5 animate-spin text-neutral-30"
        />
      </span>
    </template>
    <template v-else>
      <Icon v-if="icon" :name="icon" class="w-4 h-4 mr-2" />
      <slot />
    </template>
  </button>
</template>

<style lang="postcss">
.button {
  @apply flex items-center justify-center p-3 sm:px-6 rounded-lg text-xs font-semibold border-2;

  &.default {
    @apply bg-primary-50 text-white border-transparent duration-300 hover:bg-primary-40;
  }

  &.white {
    @apply bg-white text-primary-50 border-white;
  }

  &.error {
    @apply bg-red-400 text-white;
  }

  &.yellow {
    @apply bg-tertiary-50 text-white border-transparent hover:bg-tertiary-40 duration-300;
  }

  &.success {
    @apply bg-success-50 border-transparent hover:bg-success-50/80 duration-300 text-white;
  }

  &.outlined {
    @apply border-2 border-primary-50 text-primary-50 bg-transparent;
  }

  &.outlinedWhite {
    @apply border border-white text-white bg-transparent;
  }

  &.text {
    @apply border-0 text-primary-50 bg-transparent;
  }

  &.full {
    @apply w-full;
  }

  &.disabled {
    @apply text-neutral-400 cursor-not-allowed border-transparent bg-neutral-200;
  }

  &.loading {
    @apply bg-[#3A004D1F] border-transparent;
  }
}
</style>
