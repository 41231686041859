import revive_payload_client_2f0hBkdGXq from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_5ijd4sy3emeupn57zycnuefnme/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dc4hYYbd6N from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_5ijd4sy3emeupn57zycnuefnme/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_sTDvkcuh97 from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_5ijd4sy3emeupn57zycnuefnme/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6eDunBD3pp from "/builds/trevo/web/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_terser@5.3_wt6mwhmtjwgwldz2d6srjsetc4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_Fg9LGLwmob from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_5ijd4sy3emeupn57zycnuefnme/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uHVVlWRisY from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_5ijd4sy3emeupn57zycnuefnme/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_4ho5fgoCbM from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_5ijd4sy3emeupn57zycnuefnme/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2dQxvFTqE6 from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_5ijd4sy3emeupn57zycnuefnme/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/trevo/web/.nuxt/components.plugin.mjs";
import prefetch_client_2M71un2a2Y from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.9.1_jiti@1.21.6_5ijd4sy3emeupn57zycnuefnme/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Dc1gSdwboM from "/builds/trevo/web/node_modules/.pnpm/nuxt-gtag@2.1.0_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import api_GFfDXud5Cr from "/builds/trevo/web/plugins/api.ts";
import error_handler_kEP5FliEXj from "/builds/trevo/web/plugins/error-handler.ts";
import filters_VNgPjx6zHj from "/builds/trevo/web/plugins/filters.ts";
import maska_UHaKf2z1iQ from "/builds/trevo/web/plugins/maska.ts";
import sentry_client_shVUlIjFLk from "/builds/trevo/web/plugins/sentry.client.ts";
import toastify_ScRgZgP9op from "/builds/trevo/web/plugins/toastify.ts";
import validators_kQs43V5Huh from "/builds/trevo/web/plugins/validators.ts";
export default [
  revive_payload_client_2f0hBkdGXq,
  unhead_dc4hYYbd6N,
  router_sTDvkcuh97,
  _0_siteConfig_6eDunBD3pp,
  payload_client_Fg9LGLwmob,
  navigation_repaint_client_uHVVlWRisY,
  check_outdated_build_client_4ho5fgoCbM,
  chunk_reload_client_2dQxvFTqE6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2M71un2a2Y,
  plugin_client_Dc1gSdwboM,
  api_GFfDXud5Cr,
  error_handler_kEP5FliEXj,
  filters_VNgPjx6zHj,
  maska_UHaKf2z1iQ,
  sentry_client_shVUlIjFLk,
  toastify_ScRgZgP9op,
  validators_kQs43V5Huh
]