<script setup lang="ts">
import { EventName } from '@/modules/trevo/types/enum'
import { jwtDecode } from 'jwt-decode'
import type { IAccessTokenDecoded } from '@/modules/core/types'

const props = defineProps({
  phone: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['close', 'verified', 'retry', 'open-whatsapp-modal', 'close'])

const retry = ref(false)

const whatsAppModal = ref(false)

function closeWhatsAppModal() {
  whatsAppModal.value = false
}

function openWhatsAppModal() {
  whatsAppModal.value = true
}

const counter = ref(60)
const counterInterval = ref()

const inputRefs = reactive<Array<any>>([])

const invalidToken = ref(false)

const verificationCode = reactive<Array<string>>(Array.from({ length: 6 }))

const disabledConfirmButton = ref(true)

function checkIfTokenIsCompleted() {
  let count = 0

  for (const input of inputRefs)
    count += input.value.length

  if (count === 6)
    disabledConfirmButton.value = false
  else
    disabledConfirmButton.value = true
}

function handleDelete(event: any, index: number) {
  if ((event.key === 'Backspace' || event.key === 'Delete') && index !== 1) {
    if (inputRefs[index - 1].value)
      inputRefs[index - 1].focus()
    else
      inputRefs[index - 2].focus()
  }
}

function handleInput(event: any, index: number) {
  verificationCode[index - 1] = event.target.value

  if (event.target.value.length > 0 && index < 6)
    inputRefs[index].focus()
  else if (event.target.value.length === 0 && index > 1)
    inputRefs[index - 2].focus()

  if (event.target.value.length === 6)
    disabledConfirmButton.value = false
  else
    disabledConfirmButton.value = true

  invalidToken.value = false

  checkIfTokenIsCompleted()
}

function handlePaste(event: any) {
  const pastedData = event.clipboardData.getData('text')

  if (pastedData && pastedData.length === 6) {
    for (let i = 0; i < 6; i++) {
      inputRefs[i].value = pastedData[i]
      verificationCode[i] = pastedData[i]
      if (i < 5)
        inputRefs[i + 1].focus()
    }
  }

  checkIfTokenIsCompleted()
}

function retryOnVerifyCodeSubmit() {
  emit('retry')
  retry.value = true
  invalidToken.value = false
  for (let i = 0; i < 6; i++)
    inputRefs[i].value = ''
}

const isLoading = ref(false)

async function onVerifyCodeSubmit() {
  const payload = {
    phone: props.phone.replace(/(\+55\s)|[^\d]/g, ''),
    phoneVerificationCode: verificationCode.join(''),
  }

  try {
    isLoading.value = true

    const path = '/v1/customer/users/verify_phone_code'

    const method = 'POST'

    const baseURL = useRuntimeConfig().public.api.awsGateway

    let response = await $fetch(path, { method, baseURL, body: JSON.stringify(camelToSnake(payload)) })

    response = snakeToCamel(response)

    const decodedToken: IAccessTokenDecoded = jwtDecode(response.accessToken)

    const { accessToken, refreshToken } = useAuth()

    accessToken.value = response.accessToken
    refreshToken.value = response.refreshToken

    track(EventName.TokenVerified, {
      phone: props.phone,
      isNewUser: !decodedToken.name,
    })

    const { gtag } = useGtag()
    const { gtagId } = useRuntimeConfig().public
    gtag('config', gtagId, { ab_test: abTest(), user_id: useCustomer().customer.value?.id || null })

    emit('close')
  }
  catch {
    invalidToken.value = true
  }
  finally {
    isLoading.value = false
  }
}

function cleanCounter() {
  clearInterval(counterInterval.value)
  counterInterval.value = null
}

function startCounter() {
  counterInterval.value = setInterval(() => {
    if (counter.value === 0)
      cleanCounter()
    else
      counter.value--
  }, 1000)
}

function changePhoneNumber() {
  track(EventName.ChangePhone)

  emit('close')
}

onNuxtReady(() => {
  inputRefs[0].focus()

  startCounter()
})
</script>

<template>
  <section class="px-8 py-6">
    <div class="flex items-center justify-end py-4 w-full">
      <Icon
        name="mdi:close"
        class="text-primary-50 right-[30px] xl:right-[94px] top-11 size-4 cursor-pointer"
        @click="$emit('close')"
      />
    </div>
    <form class="flex flex-col gap-8 items-center">
      <div class="flex flex-col items-center">
        <div class="flex flex-col items-center">
          <h2 class="text-primary-50 font-bold text-2xl mb-1">
            Qual é o seu código?
          </h2>
          <p class="text-center text-base font-semibold text-neutral-30">
            <span class="block lg:inline"> Digite o código enviado para </span>
            <span class="font-bold text-neutral-50 whitespace-nowrap">{{ props.phone }}.</span>
          </p>
        </div>

        <div
          class="flex items-center justify-center cursor-pointer mt-6 mb-10"
          @click="changePhoneNumber"
        >
          <Icon
            name="ic:round-arrow-back"
            class="text-primary-40 w-6 h-6 min-w-[24px] min-h-[24px]"
          />
          <span class="text-primary-40 text-sm font-semibold ml-2">
            Trocar número de celular
          </span>
        </div>

        <div class="flex justify-center mb-4">
          <span class="text-neutral-30 text-xs font-semibold">
            Código de verificação
          </span>
        </div>

        <div class="flex flex-col items-center">
          <label
            for="active"
            class="flex justify-between items-center font-bold"
          >
            <input
              v-for="index in 6"
              :id="`active-${index}`"
              :key="index"
              :ref="(el) => (inputRefs[index - 1] = el)"
              v-maska
              data-maska="#"
              type="tel"
              name="active"
              :disabled="isLoading"
              :class="[
                [
                  `mr-1 w-10 h-14 p-2 text-center ${
                    !invalidToken ? 'border border-neutral-70' : ''
                  } rounded-lg focus:outline-primary-50`,
                ],
                { ['bg-[#7300991F] border-[#73009929]']: isLoading },
                { ['border-2 border-error-50']: invalidToken },
              ]"
              @input="(event) => handleInput(event, index)"
              @paste="(event) => handlePaste(event)"
              @keydown="(event) => handleDelete(event, index)"
            >
          </label>
          <span
            v-if="invalidToken"
            class="text-error-50 text-xs font-semibold mt-1"
          >
            Código de verificação inválido
          </span>
        </div>
      </div>

      <p v-if="counter" class="text-neutral-30 text-xs font-semibold">
        Reenviar código em
        <span class="font-bold underline">
          00:{{ counter > 10 ? counter : `0${counter}` }}
        </span>
      </p>

      <span
        v-else-if="counter === 0 && !retry"
        class="text-primary-50 text-xs font-semibold underline cursor-pointer"
        @click="retryOnVerifyCodeSubmit"
      >
        Reenviar código
      </span>

      <p v-else-if="retry" class="text-neutral-30 text-xs font-semibold">
        Problemas para
        <span
          class="text-information-50 font-semibold cursor-pointer"
          @click="openWhatsAppModal"
        >
          acessar a conta?
        </span>
      </p>

      <p class="text-neutral-50 font-semibold text-xs text-center">
        <span class="block">
          Ao clicar em Confirmar, concordo com os
          <NuxtLink no-prefetch class="text-information-50 cursor-pointer" to="#">
            Termos de Serviço
          </NuxtLink>
        </span>
        <span>
          e
          <NuxtLink no-prefetch class="text-information-50 cursor-pointer" to="#">
            Política de Privacidade
          </NuxtLink>
          da Saúde Trevo.
        </span>
      </p>

      <Button
        class="w-full md:w-[296px]"
        :disabled="disabledConfirmButton"
        :loading="isLoading"
        @click.prevent="onVerifyCodeSubmit"
      >
        Confirmar
      </Button>
    </form>
    <div class="px-10">
      <CoreWhatsAppModal
        v-if="whatsAppModal"
        text="Problemas para acessar <br /> sua conta"
        @close="closeWhatsAppModal"
      />
    </div>
  </section>
</template>
