<script setup lang="ts">
type TSize = 'default' | 'small'

defineProps({
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  size: {
    type: String as PropType<TSize>,
    default: 'default',
  },
  withoutHeader: {
    type: Boolean,
    default: false,
  },
  coloredHeader: {
    type: Boolean,
    default: false,
  },
  titleLarge: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close'])

function close() {
  emit('close')
}
</script>

<template>
  <teleport to="body">
    <div
      class="fixed inset-0 flex items-center justify-center z-50 px-4 bg-opacity-80 bg-black"
      @click="close"
    >
      <div
        :class="
          size === 'small'
            ? 'w-fit md:w-fit lg:w-fit md:px-20 px-0'
            : 'md:w-10/12 lg:w-1/2'
        "
        class="animate__animated animate__fadeInDown animate__faster"
        @click.stop
      >
        <div class="bg-white w-full rounded-lg shadow-lg xl:max-h-[716px]">
          <div
            class="bg-neutral-90 h-28 mb-8 flex items-end justify-between px-8 pb-3 pt-8 rounded-t-lg" :class="[
              {
                ['bg-gradient-to-b from-primary-70 via-primary-95 to-white mt-11 flex items-center justify-center']: coloredHeader,
              },
              { ['bg-white xl:mt-11 h-12']: withoutHeader },
            ]"
          >
            <div class="w-full">
              <h3
                class="text-sm md:text-xl" :class="[
                  {
                    ['xl:text-2xl text-primary-50 font-extrabold w-full text-center']: titleLarge,
                  },
                ]"
              >
                {{ title }}
              </h3>
              <p v-if="subtitle" class="text-center text-primary-50 font-semibold">
                {{ subtitle }}
              </p>
            </div>
            <button
              class="text-3xl"
              aria-label="Botão para fechar o modal"
              type="button"
              @click="close"
            >
              <Icon name="mdi:close" class="w-6 h-6" />
            </button>
          </div>

          <div class="px-8 mb-4">
            <div class="w-full flex justify-center">
              <div class="w-full max-w-[636px]">
                <slot name="content" />
              </div>
            </div>
          </div>

          <section class="flex justify-end">
            <slot name="footer" />
          </section>
        </div>
      </div>
    </div>
  </teleport>
</template>
