<script setup lang="ts">
type TGoogleContext = 'signin_with' | 'signup_with' | 'continue_with' | 'signin'

interface IGoogleResponse {
  clientId: string
  client_id: string
  credential: string
  select_by: string
}

const props = defineProps({
  context: {
    type: String as PropType<TGoogleContext>,
    required: true,
  },
})

const emit = defineEmits(['callback'])

declare global {
  interface Window {
    google: any
  }
}

const { $api, $toast } = useNuxtApp()

const loadingButton = ref(false)

function initializeGoogle() {
  const { googleClientId } = useRuntimeConfig().public

  window.google?.accounts?.id.initialize({
    client_id: googleClientId,
    context: props.context,
    ux_mode: 'popup',
    auto_prompt: false,
    callback: (response: IGoogleResponse) => {
      useFullPageLoading().value = true

      $api.customer
        .authGoogle(response.credential)
        .then((response) => {
          emit('callback', response)
        })
        .catch(() => {
          $toast.error('Ocorreu algum erro inesperado. Tente novamente.')
        })
        .finally(() => useFullPageLoading().value = false)
    },
  })
}

async function createButton() {
  loadingButton.value = true

  await initializeGoogle()

  if (window.google?.accounts) {
    window.google.accounts.id.prompt()

    window.google.accounts.id.renderButton(
      document.querySelector(`#${props.context}`),
      {
        locale: 'pt-BR',
      },
    )
  }

  loadingButton.value = false
}

onNuxtReady(() => {
  createButton()
})

if (process.client) {
  useHead({
    script: [
      {
        src: 'https://accounts.google.com/gsi/client',
        async: true,
        defer: true,
        onload: () => {
          createButton()
        },
      },
    ],
  })
}
</script>

<template>
  <Icon
    v-if="loadingButton"
    name="mingcute:loading-fill"
    class="animate-spin text-primary-50"
    height="24"
    width="24"
  />
  <div :id="context" class="z-10" />
</template>
