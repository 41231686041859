<script setup lang="ts">
const props = defineProps({
  hiddenTextWhenMobile: {
    type: Boolean,
    default: false,
  },

  path: {
    type: String,
    default: '',
  },

  isWhite: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()

function back() {
  if (props.path)
    navigateTo(props.path)
  else
    router.back()
}
</script>

<template>
  <button
    aria-label="Botão para voltar a página"
    class="flex items-center gap-2 font-semibold text-primary-50 my-4" :class="[
      { ['text-white']: isWhite },
    ]"
    type="button"
    @click="back"
  >
    <Icon name="ic:round-arrow-back" class="w-5 h-5 md:w-5 md:h-5" />
    <span
      :class="props.hiddenTextWhenMobile ? 'hidden xl:inline' : 'inline'"
      class="text-xs font-semibold tracking-[0.36] leading-[12px]"
    >
      Voltar
    </span>
  </button>
</template>
