<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    required: true,
  },
  checked: {
    type: Boolean,
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
})

defineEmits(['update:modelValue'])

const state = ref(false)
</script>

<template>
  <label :for="props.id" class="flex items-center cursor-pointer">
    <input
      :id="props.id"
      v-model="state"
      :name="props.name"
      :value="props.value"
      :checked="checked"
      type="radio"
      class="peer appearance-none bg-white border-2 border-primary-20 cursor-pointer rounded-full w-6 h-6 relative peer shrink-0 checked:bg-white checked:text-white checked:border checked:border-primary-50 checked:before:content-[''] before:absolute before:inset-1 before:bg-white checked:before:bg-primary-50 before:rounded-full flex justify-center items-center text-base"
      @change="() => $emit('update:modelValue', props.id)"
    >
    <span v-if="label" class="ml-3 text-neutral-20 text-sm font-semibold">
      {{ props.label }}
    </span>
  </label>
</template>
