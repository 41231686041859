<script setup lang="ts">
import { LocalStorageKey } from '@/modules/core/enums'
import { EventName } from '@/modules/trevo/types/enum'
import * as Sentry from '@sentry/vue'

const isNotLaboratory = computed(() => !useRoute().name?.toString().includes('laboratorio'))

if (isNotLaboratory.value && import.meta.client) {
  useAuthGlobally()
  useCustomerGlobally()
  useCartGlobally()
  abTest()
}

const phone = ref('')
const showTokenModal = ref(false)
const showConfirmationModal = ref(false)
const scheduling = ref({})
const gclid = useLocalStorage(LocalStorageKey.GCLID, '')

const { showSignInModal, showSignUpModal } = useAuth()

const fullPageLoading = useFullPageLoading()

function handleShowTokenModal(currentPhone: string) {
  phone.value = currentPhone

  showTokenModal.value = true
}

interface IGclidData {
  gclid: string
  expires: string
}

function useGclid() {
  try {
    const today = new Date()

    if (useRoute()?.query?.gclid) {
      const data = {
        gclid: useRoute().query.gclid as string,
        expires: new Date(today.setDate(today.getDate() + 90)).toDateString(),
      }

      gclid.value = JSON.stringify(data)

      return
    }

    if (!gclid?.value.includes('{'))
      return localStorage.removeItem(LocalStorageKey.GCLID)

    const data: IGclidData = JSON.parse(gclid.value)

    if (!data || !data.expires || !data.gclid) {
      localStorage.removeItem(LocalStorageKey.GCLID)

      throw new Error(`Gclid data is invalid: ${gclid}`)
    }

    if (new Date(data.expires) < today)
      return localStorage.removeItem(LocalStorageKey.GCLID)
  }
  catch (error) {
    Sentry.captureException(error)
  }
}

const { customer } = useCustomer()
watch(
  [
    customer,
  ],
  () => {
    try {
      const baseURL = useRuntimeConfig().public.api.awsGateway

      const { accessToken } = useAuth()

      if (!customer.value?.id || !gclid.value)
        return

      return $fetch(`/v2/customer/users/${customer.value.id}/device`, {
        baseURL,
        method: 'POST',
        body: JSON.stringify({
          gclid: getGclid(),
          device_id: useTrackMixpanelDistinctId().value,
          session_id: useTrackGoogleSessionId().value,
          user_pseudo_id: useTrackGoogleClientId().value,
        }),
        headers: {
          Authtoken: accessToken.value,
        },
      })
    }
    catch (error) {
      Sentry.captureException(error)
    }
  },
  {
    deep: true,
  },
)

onNuxtReady(async () => {
  await initializeTracking()

  useGclid()

  track(EventName.PageView)
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />

      <TFullPageLoading v-if="fullPageLoading" />

      <template v-if="isNotLaboratory">
        <CartPromoSideSheet :show-promo="usePromoModalActiveState().value" @close="usePromoModalClose" />

        <ProfilePromoAdded />

        <Dialog :show="showSignInModal" @close="showSignInModal = false">
          <CustomerSignInModal
            v-if="showSignInModal"
            @close="showSignInModal = false"
            @send-token="handleShowTokenModal"
          />
        </Dialog>

        <Dialog
          :show="showSignUpModal"
        >
          <CustomerSignUpModal
            v-if="showSignUpModal"
            @close="showSignUpModal = false"
          />
        </Dialog>

        <Dialog
          not-sidesheet
          :show="showTokenModal"
          @close="showTokenModal = false"
        >
          <CustomerTokenModal
            v-if="showTokenModal"
            :phone="phone"
            :show="showTokenModal"
            @close="showTokenModal = false"
          />
        </Dialog>

        <Dialog
          :show="showConfirmationModal"
          @close="showConfirmationModal = false"
        >
          <ProfileProcedureSchedulingConfirmationModal
            v-if="showConfirmationModal"
            :scheduling="scheduling"
            :show="showConfirmationModal"
            @close="showConfirmationModal = false"
          />
        </Dialog>
      </template>
    </NuxtLayout>
  </div>
</template>
