<script lang="ts" setup>
import { useVuelidate } from '@vuelidate/core'
import type { ICustomer } from '@/modules/customer/types/customer.d'

const emit = defineEmits(['close'])

const { $filters, $validators } = useNuxtApp()

interface IForm {
  name: string
  phone: string
  email: string
  birthDate: string
  gender: string | undefined
  taxId: string | undefined
}

const { logout } = useAuth()

const { customer, loading, genderOptions } = useCustomer()

const form = reactive<IForm>({
  name: `${customer.value.name || ''} ${customer.value.lastName || ''}`.trim(),
  phone: customer.value.phone || '',
  email: customer.value.email || '',
  birthDate: $filters.formatDateEnToBr(customer.value.birthDate),
  gender: customer.value.gender,
  taxId: customer.value.taxId,
})

const rules = computed(() => ({
  name: {
    required: $validators.required(),
    fullname: $validators.fullname(),
    minLength: $validators.size({
      size: 4,
      message: 'Informe no mínimo 4 caracteres',
    }),
  },
  phone: {
    required: $validators.required(),
  },
  email: {
    required: $validators.required(),
    email: $validators.email(),
  },
  birthDate: {
    required: $validators.required(),
    minLength: $validators.size({
      size: 10,
      message: 'Informe a data completa',
    }),
    birthdate: $validators.birthdate(),
  },
  gender: {
    required: $validators.required(),
  },
  taxId: {
    required: $validators.required(),
    taxId: $validators.taxId(),
  },
}))

const validation = useVuelidate(rules, form)

async function saveUser() {
  const [name, lastName] = form.name.split(' ')

  const payload: ICustomer = {
    name,
    lastName,
    phone: $filters.phone(form.phone),
    email: form.email,
    gender: form.gender,
    taxId: form.taxId,
    birthDate: $filters.formatDateBrToEn(form.birthDate || ''),
  }

  if (customer.value.exp)
    delete customer.value.exp

  customer.value = { ...customer.value, ...payload }

  if (useRoute().name?.toString().includes('checkout')) {
    loading.value = false

    emit('close')
  }
}

onNuxtReady(validation.value.$reset)
</script>

<template>
  <div class="py-3 xl:py-14 xl:px-8">
    <section class="flex flex-col items-center justify-center px-4 text-neutral-30 text-center w-full lg:px-0">
      <h3 class="text-primary-40 text-3xl">
        Cadastro
      </h3>

      <p class="text-center mt-3 text-primary-20 text-sm">
        Solicitamos apenas as informações essenciais para o seu cadastro. Essas informações também farão parte do seu
        prontuário médico.
      </p>

      <span
        class="text-primary-40 text-xs leading-4 mt-4 underline font-semibold cursor-pointer"
        @click="logout"
      >
        Não sou eu
      </span>

      <form class="flex flex-col items-center justify-center gap-2 w-full mt-6">
        <TInputText
          id="name" v-model="form.name" label="Nome Completo" class="mt-3" :max="80"
          :validation="validation.name" @change="validation.name.$touch"
        />

        <div class="flex flex-col gap-4 w-full lg:flex-row justify-center">
          <TInputText
            id="phone"
            v-model="form.phone"
            mask="+55 (##) 9####-####"
            disabled
            :max="19"
            label="Celular"
            :validation="validation.phone"
            @change="validation.phone.$touch"
          />

          <TInputText
            id="cpf"
            v-model="form.taxId"
            mask="###.###.###-##"
            label="CPF"
            :validation="validation.taxId"
            @change="validation.taxId.$touch"
          />
        </div>

        <TInputText
          id="email"
          v-model="form.email"
          label="E-mail"
          :validation="validation.email"
          @change="validation.email.$touch"
        />

        <div class="flex flex-col gap-4 w-full lg:flex-row justify-center">
          <TInputText
            id="birt"
            v-model="form.birthDate"
            label="Data de Nascimento"
            mask="##/##/####"
            :validation="validation.birthDate"
            @change="validation.birthDate.$touch"
          />

          <InputSelect
            id="gender"
            v-model="form.gender"
            label="Gênero"
            class="w-full"
            :options="genderOptions"
          />
        </div>

        <p class="flex gap-2 mt-2 pl-3 text-xs font-semibold text-neutral-20">
          <span>
            Ao clicar em <b>Cadastrar</b>, concordo com os
            <NuxtLink no-prefetch to="/termos-e-condicoes" class="text-information-50 cursor-pointer">
              Termos de Serviço
            </NuxtLink>
            e
            <NuxtLink no-prefetch to="/politicas-de-privacidade" class="text-information-50 cursor-pointer">
              Política de Privacidade
            </NuxtLink>
            da Saúde Trevo.
          </span>
        </p>

        <div class="w-full px-6 lg:px-0 mt-16 lg:mt-14">
          <Button
            type="submit"
            :disabled="validation.$invalid"
            :loading="loading"
            class="w-full lg:w-[296px] mb-2 lg:mx-auto"
            @click.prevent="saveUser"
          >
            Cadastrar
          </Button>
        </div>
      </form>
    </section>
  </div>
</template>
