<script lang="ts" setup>
import { UserIdentifiersEvent } from '@/modules/core/enums'
import { EventName } from '@/modules/trevo/types/enum'

const emit = defineEmits(['close', 'sendToken'])

const phone = ref('')

const { showSignInModal, signInModalWithoutGoogle, loading } = useAuth()

function handleClose() {
  showSignInModal.value = false
}

async function sendToken() {
  track(EventName.SendToken, { phone: phone.value })

  await useAuthSendToken({
    phone: phone.value.replace(/(\+55\s)|[^\d]/g, ''),
    userIdentifiers: {
      userPseudoId: useTrackGoogleClientId().value,
      event: UserIdentifiersEvent.LOGIN,
      gclid: getGclid(),
      deviceId: useTrackMixpanelDistinctId().value,
      sessionId: useTrackGoogleSessionId().value,
    },
  })

  emit('sendToken', phone.value)

  handleClose()
}

function signInGoogle(response: any) {
  const { accessToken, refreshToken } = useAuth()

  if (response.error && response.error.value) {
    const { $toast } = useNuxtApp()

    $toast.error('Falha ao tentar fazer login com Google, tente novamente.')

    return
  }

  if (response.data && response.data.value.accessToken) {
    accessToken.value = response.data.value.accessToken
    refreshToken.value = response.data.value.refreshToken

    const { gtag } = useGtag()
    const { gtagId } = useRuntimeConfig().public
    gtag('config', gtagId, { ab_test: abTest(), user_id: useCustomer().customer.value?.id || null })

    emit('close')
  }

  const { customer } = useCustomer()

  customer.value = response.data.value
}

watch(showSignInModal, (show) => {
  if (show) {
    track(EventName.OpenLoginModal)
  }
})
</script>

<template>
  <div class="py-14 xl:px-28">
    <div class="flex items-center justify-end">
      <Icon
        name="mdi:close" class="text-primary-50 absolute right-[30px] xl:right-[94px] top-11 cursor-pointer"
        @click="handleClose"
      />
    </div>

    <section class="flex flex-col items-center px-4">
      <h3 class="text-primary-40 text-3xl">
        Qual seu número?
      </h3>

      <p class="text-center mt-3 text-primary-20 text-sm">
        <span>Enviaremos um código por mensagem de texto para verificar seu telefone.</span>
      </p>

      <InputPhone
        v-model="phone"
        placeholder="Celular"
        name="phone"
        class="mt-9 mb-2 w-full lg:max-w-[325px]"
      />

      <div class="max-w-[400px]">
        <p
          class="text-center text-neutral-30 text-xs font-semibold py-6"
        >
          <span class="block font-semibold">
            Ao clicar em
            <strong class="font-extrabold">Enviar código SMS</strong>, concordo em
            receber mensagens de texto de acordo com os
            <NuxtLink
              no-prefetch
              class="text-information-50 cursor-pointer"
              to="/termos-e-condicoes"
            >
              Termos e Condições
            </NuxtLink>
            da Saúde Trevo.
          </span>
        </p>
      </div>

      <Button
        class="mt-7 w-full lg:w-[296px]"
        :loading="loading"
        :disabled="phone.length < 19"
        @click="sendToken"
      >
        Enviar código SMS
      </Button>

      <template v-if="!signInModalWithoutGoogle">
        <DividerText class="my-4">
          ou
        </DividerText>

        <LoginGoogle
          context="signin"
          @callback="signInGoogle"
        />
      </template>
    </section>
  </div>
</template>
