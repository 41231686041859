import { LocalStorageKey } from '@/modules/core/enums'
import * as Sentry from '@sentry/vue'

export default (): string | null => {
  try {
    const gclid = localStorage.getItem(LocalStorageKey.GCLID)

    if (!gclid)
      return null

    return JSON.parse(gclid)?.gclid
  }
  catch (error) {
    Sentry.captureException(error)

    return null
  }
}
