import useAuth from './useAuth'

interface ISendTokenPayload {
  phone: string
  sessionId: string
}

export default async (payload: ISendTokenPayload) => {
  const { loading } = useAuth()

  try {
    loading.value = true

    const baseURL = useRuntimeConfig().public.api.awsGateway
    const path = `/v2/customer/users/request_phone_code`

    await $fetch(path, { baseURL, method: 'POST', body: JSON.stringify(camelToSnake(payload)) })
  }
  catch {
    const { $toast } = useNuxtApp()

    $toast.error('Falha ao enviar código de verificação SMS.')
  }
  finally {
    loading.value = false
  }
}
